<template>
  <Sidebar :loading="loading"
           :instance="instance"
           @close="$router.push({name: 'team.index'})"
  >
    <template v-if="instance">
      <TeamLayout>
        <template v-slot:avatar>
          <Avatar size="80" :subject="instance" :layout="{rounded: false}" ></Avatar>
        </template>
        <template v-slot:name>
          <h1 class="text-h4 font-weight-bold">{{ instance.name }}</h1>
        </template>
        <template v-slot:role>
          <div class="font-weight-bold">{{ instance.role }}</div>
        </template>
        <template v-slot:login>
          <v-text-field size="80"
              id="login"
              :value="instance.login"
              dense
              filled
              background-color="transparent"
              readonly
              @click="$event.target.select()"
              hide-details
              class="ml-n3"
          ></v-text-field>
        </template>
        <template v-slot:password>
          <v-text-field
              id="password"
              :value="instance.password"
              dense
              filled
              background-color="transparent"
              readonly
              @click="$event.target.select()"
              hide-details
              class="ml-n3"
          ></v-text-field>
        </template>
      </TeamLayout>

      <v-spacer></v-spacer>

      <v-container fluid>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn large color="error" @click="confirmAndDelete()" plain>Delete</v-btn>
          <v-btn large depressed :to="{name: 'team.edit', params: {uuid: instance.uuid}}">Edit</v-btn>
        </v-card-actions>
      </v-container>

    </template>
  </Sidebar>
</template>

<script>
import Sidebar from "../../components/layout/Sidebar";
import {mapActions, mapState} from "vuex";
import Avatar from "../../components/common/Avatar";
import TeamLayout from "../../components/layout/TeamLayout";

export default {
  name: "TeamViewer",
  components: {TeamLayout, Avatar, Sidebar},
  props: [
    'uuid'
  ],
  computed: {
    ...mapState('team/instance', {
      instance: 'instance',
      loading: 'loading',
    }),
  },
  methods: {
    ...mapActions('team/instance', {
      destroy: 'destroy',
      cancel: 'cancel',
    }),
    confirmAndDelete() {
      if (window.confirm('Are you sure?')) {
        return this.destroy();
      }
    }
  },
}
</script>